
  .ptags{
    font-size: 18px;
    margin-top: 4px;
    margin-right: 8px;
    display: inline;
  }
  
  .calWidth {
    max-width: 400px;
  }
  
  #addBookingContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 260px;
  }
  
  .holder {
    width: 355px;
    padding-left: 20px;
  }
  
  #coolContainer {
    padding: 10px 10px 10px 10px;
    border-bottom: 2px solid black;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #newContainer {
    border-bottom: 1px solid gray;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
  
  }
  
  
  #divWhereWidthChanges {
    display: flex;
    flex-direction: column;
  }
  
  #bookingHolder {
    width: 375px;
    overflow: auto;
    display: inline-block;
    background-color: white;
    box-shadow: 0px 0px 7px white;
  }
  
  .bookingHolderSubContainer {
    position: relative;
    top: 12px;
    height: 120px;
    width: 210px;
    border: 2px solid black;
    overflow: auto;
  }
  
  .itemPTag {
    padding: 1px 0px 1px 10px;
    cursor: pointer;
  }
  
  .itemPTag:hover {
    background-color: rgb(157, 217, 255);
  }
  
  
  @media (min-width: 1100px) {
      #newContainer {
        width: 100%;
      }
  }
  
  
  @media(max-width: 600px) {
    #bookingHolder {
      width: 100%;
      max-height: none;
      overflow: visible;
    }
    .calWidth {
      max-width: none;
      align-items: center;
    }
    #newContainer {
      align-items: center;
    }
  }
  