#container {
    display: flex;
    width: 100%;
}

#leftHolder {
    padding: 15px;
    border-right: 1px solid gray;
    background-color: rgb(221, 221, 221);
}

#rightHolder {
    display: flex;
    justify-content: center;
    background-color: rgb(0, 240, 0, 0.1);
    width: 100%;
}


@media(max-width: 750px) {
    #container {
        flex-direction: column-reverse;
    }
    #leftHolder {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 2;
        padding-left: 0px;
        border-right: none;
        padding-right: 0;
    }

    #rightHolder {
        padding-bottom: 50px;
    }
}



