#weekSelectorContainer {
    width: 330px;
    padding: 0px 4px;
    height: 260px;
    box-shadow: 0px 0px 3px black;
    background-color: #ffffff;
    padding-top: 4px;
    top: 5px;
    left: 5px;
}

.day {
    width: 32px;
    margin-top: 2px;
    padding: 4px;
    border: 1px solid black;
    
    cursor: pointer;
}

#preMonthPostMonth {
    color: rgb(0, 0, 0);
}

#preMonthPostMonth:hover {
    color: white;
}


.day:hover {
    background-color: gray;
    color: white;
}

#days {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    position: relative;
}

#calHeader {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
