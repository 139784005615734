#main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('../mfcbacko.jpg') no-repeat center center fixed;
    background-size: cover;
}

#header {
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Amaranth';
}

#heighto {
    height: 850px;
}

#boxy {
    height: 600px;
    width: 400px;
}

#body {
    display: flex;
    padding-top: 40px;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 100px;
}

#bigGuy:hover {
    background-color: rgb(223, 223, 223);
    cursor: pointer;
    box-shadow: 0px 0px 4px black;
}

@media(max-width: 550px) {

}

@media(max-width: 800px) {
    #body {
        flex-direction: column;
        align-items: center;
        height: 1000px;
        padding-bottom: 50px;
    }
    #heighto {
        height: 1145px;
    }
    #header {
        font-size: 30px;
        padding-bottom: 0px;
    }
    #boxy {
        height: 420px;
        width: 360px;
    }
}