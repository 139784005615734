#login {
    display: flex;
    flex-direction: column;
    height: 280px;
    justify-content: space-around;
    width: 370px;
    align-items: center;
}


#mainDog {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#createEmployees {
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

