#scheduleContainer {
    width: 328px;
    margin-top: 30px;
    padding-left: 9px;
    height: 450px;
    max-height: 450px;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: rgb(68,68,68);
    border: 1.2px solid #f9e9f9;
}

.inputs {
    margin-left: 5px;
    width: 150px;
}

option {
    background-color: black;
}