.shiftContainer {
    flex-direction: column;
    display: flex;
    height: 110px;
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}


.insideShiftContainer {
    display: flex;
    justify-content: space-between;
}

.flipper {
    display: flex;
    justify-content: space-around;
    height: 60px;
    flex-direction: column;
}


#shiftSchedule {
    margin-top: 30px;

}

#scrollable {
    max-height: 600px;
    overflow: auto;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 1000px) {
    #shiftSchedule {
        position: relative;
    }
}