* {
    color: black;
    border-radius: 0%;
}


.main {
    display: flex;
    justify-content: center;
    font-family: 'Amaranth';
    align-items: c;
    width: 100%;
    background: url('mfcbacko.jpg') no-repeat center center fixed;
    background-size: cover;
    height: 1100px;
  }
  
  .cover {
      background-color: rgb(0, 0, 0);
      width: 100%;
      position: absolute;
      height: 1100px;
      opacity: 70%;
      display: flex;
      justify-content: center;
      
  }
  
  #welcome {
      color: rgb(222, 255, 222);
      line-height: 24px;
      font-size: 16px;
      height: 100px;
      width: 360px;
  
  }
  
  
  #sub_main {
      z-index: 1000000;
      display: flex;
      position: absolute;
      flex-direction: column;
      top: 130px;
      height: 650px;
  }
  
  #cool {
      width: 330px;
      background: rgba(255, 255, 255, 0.000);
      height: 655px;
      padding-top: 10px;
      margin-top: 30px;   
      padding-left: 35px;
      box-shadow: 0px 0px 4px white;
  }
  
  #otherCool {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;
  }
  
  .inny {
      background-color: white;
      border: none;
      box-shadow: 0px 0px 4px lightgreen;
      font-size: 18px;
      width: 300px;
      padding-left: 6px;
      height: 26px;
  }
  
