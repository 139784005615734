/* #bigDog {
    width: 100%;
    display: flex;
} */






#smallDiv {
 width: 600px;
}

.smallerDiv {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

@media(max-width: 900px) {
    #switch {
        flex-direction: column;
        align-items: center;
        height: 180px;
    }
    #smallDiv {
        width: 300px;
        flex-direction: column;
    }
    .smallerDiv {
        width: 100%;
    }
}


