#scheduleContainer {
    width: 330px;
    margin-top: 30px;
    padding-left: 9px;
    height: 450px;
    max-height: 450px;
    display: flex;
    position: relative;
    flex-direction: column;
    box-shadow: 0px 0px 3px black;
    background-color: white;
}

.inputs {
    margin-left: 5px;
    width: 150px;
    box-shadow: 0px 0px 4px black;
}

#buu {
 background-color: white;
 cursor: pointer;
}

#buu:hover {
    background-color: rgb(213, 213, 213);
}

option {
    background-color: black;
}

.seePink {
    background-color: transparent;
    box-shadow: 0px 0px 4px black;
    border: none;
    padding: 2px 2px 2px 8px;
}