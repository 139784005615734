#mainContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

#subContainer {
    width: 218px;
    display: flex;
    box-shadow: 0px 0px 3px black;
    cursor: pointer;
    padding: 4px 10px 4px 20px;
    align-items: center;
    height: 30px;
    margin-top: 6px;
}

#button {
    border: none;
    background-color: transparent;
    margin-top: 3px;
}